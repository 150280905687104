import _ from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useLoaderData } from "react-router";
import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useLocationPusher } from "~/components/link";
import {
  ContactContext,
  ContentTopicContext,
  type TrainingContextType,
  useOptionalProject
} from "~/contexts";
import type {
  PhotosetFieldsFragment,
  Project,
  ProjectUsersQuery,
  ShowContactFieldsFragment
} from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { useFetcherData } from "~/utils/remix";

type Base = {
  injectOptionGroup?: { label: string; options: ComboBoxOption[] };
  injectOptions?: ComboBoxOption[];
  injectOptionsAfter?: ComboBoxOption[];
  project?: Pick<Project, "id"> | null;
  photoset?: PhotosetFieldsFragment | null;
  training?: TrainingContextType;
  contact?: ShowContactFieldsFragment;
  searchPriorStaff?: boolean;
  redirect?: boolean;
};

export type UserPickerPropsSingle = Base & ComboBoxParentPropsSingle;
export type UserPickerPropsMulti = Base &
  Omit<ComboBoxParentPropsMultiple, "redirect">;

export default function UserPicker({
  injectOptions,
  injectOptionGroup,
  injectOptionsAfter,
  project: sourceProject,
  contact: sourceContact,
  photoset,
  training: sourceTraining,
  placeholder = "Choose someone...",
  searchPriorStaff,
  redirect,
  ...rest
}: UserPickerPropsSingle | UserPickerPropsMulti) {
  const currentUser = useCurrentUser();
  const projectCtx = useOptionalProject();
  const contactCtx = useContext(ContactContext);
  const push = useLocationPusher();
  const [initialValue] = useState(rest.value || rest.defaultValue);
  const includeIds = useMemo(
    () =>
      Array.isArray(initialValue)
        ? initialValue
        : initialValue
          ? [initialValue]
          : null,
    [initialValue]
  );
  const loader = useLoaderData<{ userPicker?: ProjectUsersQuery } | null>();

  // No need to check includeIds, for now the picker always returns
  // all users.
  const alreadyFetched =
    loader?.userPicker &&
    // !includeIds?.length &&
    (!sourceProject || projectCtx) &&
    (!sourceContact || contactCtx);
  const fetcher = useFetcherData<ProjectUsersQuery>(
    alreadyFetched ? undefined : "/resources/users/picker",
    {
      includeProject: !!sourceProject,
      includeContact: !!sourceContact,
      id: sourceProject?.id || sourceContact?.id || "0"
    }
  );

  const users = useMemo(
    () =>
      fetcher.data?.users ||
      loader?.userPicker?.users ||
      fetcher.data?.users ||
      [],
    [fetcher.data?.users, loader?.userPicker?.users]
  );
  const project = fetcher.data?.project || projectCtx;
  const contact = fetcher.data?.contact || contactCtx;
  const contentTopic = useContext(ContentTopicContext);

  const selectedIsFormer = (includeIds || []).some((id) => {
    const u = users.find((u) => u.id === id);
    return u?.employmentStatus !== "ACTIVE";
  });

  const options = useMemo(() => {
    const projectOptions: ComboBoxOption[] = [];
    if (project) {
      if (rest.multiple) {
        projectOptions.push({
          value: "TEAM",
          label: "Entire Team"
        });
      }

      if (project.mode === "Opportunity" && project.opportunityOwner) {
        projectOptions.push({
          value: project.opportunityOwner.id,
          label: `${project.opportunityOwner.fullname} (Opp. Owner)`,
          login: project.opportunityOwner.login
        });
      }
      if (project.pm) {
        projectOptions.push({
          value: project.pm.id,
          label: `${project.pm.fullname} (${
            project.mode === "Campaign" ? "CM" : "PM"
          })`,
          login: project.pm.login
        });
      }
      if (project.pc) {
        projectOptions.push({
          value: project.pc.id,
          label: `${project.pc.fullname} (${
            project.mode === "Campaign" ? "CC" : "PC"
          })`,
          login: project.pc.login
        });
      }
      if (project.technicalLead) {
        projectOptions.push({
          value: project.technicalLead.id,
          label: `${project.technicalLead.fullname} (TL)`,
          login: project.technicalLead.login
        });
      }
      _.sortBy(project.projectUsers, (pu) => pu.role !== "Expert").forEach(
        (pu) =>
          projectOptions.push({
            value: pu.user.id,
            label: `${pu.user.fullname} (${pu.role})`,
            login: pu.user.login
          })
      );
    }
    if (sourceTraining) {
      if (sourceTraining.expert) {
        projectOptions.push({
          value: sourceTraining.expert.id,
          label: `${sourceTraining.expert.fullname} (SME)`,
          login: sourceTraining.expert.login
        });
      }
      _.sortBy(
        users.filter(
          (u) =>
            u.id !== sourceTraining.expert?.id &&
            u.roles.includes("Training: Administrator")
        ),
        "fullname"
      ).forEach((user) => {
        projectOptions.push({
          value: user.id,
          label: `${user.fullname} (Training Manager)`,
          login: user.login
        });
      });
    }
    if (contact?.owner) {
      projectOptions.push({
        value: currentUser.id,
        label: `Me (${currentUser.fullname})`,
        login: currentUser.login
      });
      projectOptions.push({
        value: contact.owner.id,
        label: `Relationship Manager (${contact.owner.fullname})`,
        login: contact.owner.login
      });
    }
    if (photoset?.user) {
      projectOptions.unshift({
        value: photoset.user.id,
        label: `Investigator (${photoset.user.fullname})`,
        login: photoset.user.login
      });
    }
    if (contentTopic?.owner) {
      projectOptions.push({
        value: contentTopic.owner.id,
        label: `${contentTopic.owner.fullname} (Content Owner)`,
        login: contentTopic.owner.login
      });
    }

    const values = projectOptions.map((o) => o.value);

    const options = [
      ...(injectOptions || []),
      ..._.sortBy(
        users.filter((u) => !values.includes(u.id)),
        "fullname"
      ).map(
        (u): ComboBoxOption => ({
          value: u.id,
          label: u.fullname,
          login: u.login,
          extra: { active: u.employmentStatus === "ACTIVE" }
        })
      ),
      ...(injectOptionsAfter || [])
    ];

    const active = options.filter(
      (o) => !o.extra || (o.extra as { active: boolean })?.active
    );
    const inactive = options.filter(
      (o) => !(o.extra as { active: boolean })?.active
    );
    const selectedButInactive = inactive.filter((o) =>
      includeIds?.includes(o.value)
    );

    if (project) {
      return [
        { label: "Project Team", options: projectOptions },
        injectOptionGroup,
        { label: "Other Staff", options: active },
        ...(selectedButInactive.length && !searchPriorStaff
          ? [{ label: "Former Staff", options: selectedButInactive }]
          : []),
        ...(searchPriorStaff
          ? [{ label: "Former Staff", options: inactive }]
          : [])
      ].filter(Boolean);
    }
    if (projectOptions.length > 0) {
      return [
        { label: "Primary Staff", options: projectOptions },
        injectOptionGroup,
        {
          label: "Other Staff",
          options: options.filter(
            (o) => !o.extra || (o.extra as { active: boolean })?.active
          )
        },
        ...(selectedButInactive.length && !searchPriorStaff
          ? [{ label: "Former Staff", options: selectedButInactive }]
          : []),
        ...(searchPriorStaff || selectedIsFormer
          ? [
              {
                label: "Former Staff",
                options: options.filter(
                  (o) => !(o.extra as { active: boolean })?.active
                )
              }
            ]
          : [])
      ].filter(Boolean);
    }

    return [
      injectOptionGroup,
      { label: "Current Staff", options: active },
      ...(searchPriorStaff || selectedIsFormer
        ? [{ label: "Former Staff", options: inactive }]
        : [])
    ].filter(Boolean);
  }, [
    project,
    sourceTraining,
    contact,
    injectOptions,
    injectOptionGroup,
    injectOptionsAfter,
    includeIds,
    searchPriorStaff,
    users,
    currentUser,
    photoset,
    selectedIsFormer,
    rest.multiple,
    contentTopic
  ]);

  const onChange = useCallback(
    (name: string, value: string | string[]) => {
      if (redirect) {
        push({ [name]: value || undefined });
      } else if (
        project &&
        rest.multiple &&
        _.isArray(value) &&
        value.includes("TEAM")
      ) {
        const next = _.without(value, "TEAM");
        const teamIds = [
          project.mode === "Opportunity" && project.opportunityOwner
            ? project.opportunityOwner.id
            : null,
          project.pm?.id,
          project.pc?.id,
          project.technicalLead?.id,
          ...project.projectUsers.map((pu) => pu.user.id)
        ]
          .filter(Boolean)
          .filter((id) => !next.includes(id!)) as string[];

        const full = _.uniqBy([...next, ...teamIds], (id) => id!);

        rest.onChange?.(name, full);

        // noop
      } else {
        if (rest.multiple) {
          rest.onChange?.(name, value as string[]);
        } else {
          rest.onChange?.(
            name,
            value as string,
            users.find((u) => u.id === value)
          );
        }
      }
    },
    [push, redirect, users, rest.multiple, rest.onChange, project]
  );

  const filterOptions = useCallback(
    (options: ComboBoxOption[], input: string) => {
      return options.filter((data) => {
        if (input) {
          if (
            !searchPriorStaff &&
            !(includeIds || []).includes(data.value) &&
            data.extra &&
            !(data.extra as { active: boolean })?.active
          ) {
            return false;
          }
          return (
            data.label.toLowerCase().includes(input.toLowerCase()) ||
            (!!data.login &&
              data.login!.toLowerCase().includes(input.toLowerCase()))
          );
        }

        return (
          !data.extra ||
          (includeIds || []).includes(data.value) ||
          (data.extra as { active: boolean })!.active
        );
      });
    },
    [includeIds, searchPriorStaff]
  );

  return (
    <ComboBox
      {...rest}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      filterOptions={filterOptions}
    />
  );
}
